@import url(https://fonts.googleapis.com/css?family=Lato:wght@200..900&display=swap);
body {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-weight: 700;
}

main {
  min-height: 80vh;
}

#root {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 100vh;
}

footer {
  padding: 1em;
}

.chain-selector {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 60px;
}

.navbar-brand img {
  margin-left: 0.7em;
  max-height: 30px;
}

footer p {
  margin-bottom: 0em;
}

.nav .dropdown img {
  max-height: 2em;
}

.section-dividers {
  width:50px;
  height:50px;
  position: absolute;
  margin:-25px 0 0 -25px;
}

.section-divider {
  text-align: center;
  transform: translateY(50%) rotate(45deg);
}
.navbar .dropdown-item img {
    max-height: 20px;
}
.dropdown-toggle.nav-link {
    margin-top: 0.5rem;
}
/*
.navbar .dropdown-menu {
    background-color: rgba(0,0,0,0.8);
    
}
.navbar .dropdown-menu .dropdown-item {
    color: white;
}
.navbar .dropdown-menu .dropdown-item:hover {
    background-color: rgba(64,64,64,0.8);
}
*/
/* body {
    overflow-x:hidden;
}
 */
.navbar {
    background-color: rgba(0,0,0,0.6);
}
.navbar-brand img {
    max-height: 40px;
}
.navbar-dark .navbar-nav .nav-link,
.navbar a.nav-link {
    color: white;
    text-transform: uppercase;
}

.navbar .account-name {
    color: lightgrey;
}

.navbar .navbar-toggler { 
    margin-left: 0.5em;
}

.navbar .menu-spacer {
    margin-left: 300px;
    margin-right: 100px;
}
.crowdsale-intro {
    min-height: 60vh;
    overflow: auto;
    padding-bottom: 10vh;
}
.crowdsale-step-one {
    min-height: 70vh;
    overflow: auto;
    padding-bottom: 10vh;
}

/* .crowdsale-hero {
} */

.crowdsale-hero h1 {
    text-transform: uppercase;
    font-size: 3.0em;
}
.crowdsale-hero h1, .crowdsale-hero p.description {
    text-align: center;
}
.crowdsale-hero p.description {
    font-size: 1.3em;
    font-weight: 300;
}

.accent, .crowdsale-intro a {
    color: #ffb400;
}

.crowdsale-intro .btn,
.crowdsale-step-one .btn {
    text-transform: uppercase;
    transition: all 0.2s;
    color: white;
}

.crowdsale-intro .btn.btn-lg {
    font-size: 1.5em;
}
.crowdsale-intro .progress {
    height: 20px;
}
.crowdsale-step-one {
    background-color: white;
}
.crowdsale-step-one h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: lightgrey;
    text-align: center;
    margin-bottom: 1em;
}

.crowdsale-step-one p {
    color: black;
    font-size: 1.2em;
}
.crowdsale-step-one p.description {
    text-align: center;
    color: rgb(55, 130, 153);
}


.crowdsale-add-token {
/*    background-color: white; */
}
.crowdsale-add-token h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: white;
    text-align: center;
    margin-bottom: 1em;
}
.crowdsale-add-token h3 {
    color: white;
}
.crowdsale-add-token p,
.crowdsale-add-token ol,
.crowdsale-add-token li {
    color: white;
    font-size: 1.0em;
}
.crowdsale-add-token table {
    background-color: rgba(255,255,255,0.9);
}
.crowdsale-add-token a {
    color: #ffb400;
}
.crowdsale-add-token a {
    font-weight: bold;
}
.crowdsale-add-token p {
    font-size: 1.1em;
}
.crowdsale-add-token p.description {
    text-align: center;
    color: rgb(55, 130, 153);
}


.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.strong {
    font-weight: bold;
}

.info-box {
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
}



.fancy {
    line-height: 0.5;
    text-align: center;
  }
.fancy span {
    display: inline-block;
    position: relative;  
}
.fancy span:before,
.fancy span:after {
    content: "";
    position: absolute;
    height: 50%;
    border-bottom: 1px solid lightgrey;
    /* border-top: 1px solid lightgrey; */
    top: 0;
    width: 100%;
}
.fancy span:before {
    right: 120%;
    margin-right: 15px;
}
.fancy span:after {
    left: 120%;
    margin-left: 15px;
}

.section-divider {
    display: none;
}

/* .rewards-hero {
} */

.rewards-hero h1 {
    text-transform: uppercase;
    font-size: 3.8em;
}
.rewards-hero h1, .rewards-hero p.description {
    text-align: center;
}
.rewards-hero p.description {
    font-size: 1.3em;
    font-weight: 300;
}

.rewards-add-token {
    background-color: white;
}
.rewards-add-token h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: lightgrey;
    text-align: center;
    margin-bottom: 1em;
}
.rewards-add-token h3 {
    color: black;
}
.rewards-add-token p,
.rewards-add-token ol,
.rewards-add-token li {
    color: black;
    font-size: 1.0em;
}
.rewards-add-token p {
    font-size: 1.1em;
}
.rewards-add-token p.description {
    text-align: center;
    color: rgb(55, 130, 153);
}

.rewards-partners .btn {
    margin-right: 1em !important;
    margin-bottom: 1em !important;
}

.modal-dialog .modal-header button.close {
    background-color: white;
    border: 0px;
}

.section-divider {
    display: none;
}

/* .redeem-hero {
} */

.redeem-hero h1 {
    text-transform: uppercase;
    font-size: 3.8em;
}
.redeem-hero h1, .redeem-hero p.description {
    text-align: center;
}
.redeem-hero p.description {
    font-size: 1.3em;
    font-weight: 300;
}

.redeem-add-token {
    background-color: white;
}
.redeem-add-token h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: lightgrey;
    text-align: center;
    margin-bottom: 1em;
}
.redeem-add-token h3 {
    color: black;
}
.redeem-add-token p,
.redeem-add-token ol,
.redeem-add-token li {
    color: black;
    font-size: 1.0em;
}
.redeem-add-token p {
    font-size: 1.1em;
}
.redeem-add-token p.description {
    text-align: center;
    color: rgb(55, 130, 153);
}

.redeem-partners .btn {
    margin-right: 1em !important;
    margin-bottom: 1em !important;
}

.modal-dialog .modal-header button.close {
    background-color: white;
    border: 0px;
}

.section-divider {
    display: none;
}
.not-found-hero h1 {
    text-transform: uppercase;
    font-size: 3.8em;
}
.not-found-hero h1, .not-found-hero p.description {
    text-align: center;
}
.not-found-hero p.description {
    font-size: 1.3em;
    font-weight: 300;
}
