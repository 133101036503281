/* body {
    overflow-x:hidden;
}
 */
.navbar {
    background-color: rgba(0,0,0,0.6);
}
.navbar-brand img {
    max-height: 40px;
}
.navbar-dark .navbar-nav .nav-link,
.navbar a.nav-link {
    color: white;
    text-transform: uppercase;
}

.navbar .account-name {
    color: lightgrey;
}

.navbar .navbar-toggler { 
    margin-left: 0.5em;
}

.navbar .menu-spacer {
    margin-left: 300px;
    margin-right: 100px;
}