@import url('https://fonts.googleapis.com/css?family=Lato:wght@200..900&display=swap');

body {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-weight: 700;
}

main {
  min-height: 80vh;
}

#root {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 100vh;
}

footer {
  padding: 1em;
}

.chain-selector {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 60px;
}

.navbar-brand img {
  margin-left: 0.7em;
  max-height: 30px;
}

footer p {
  margin-bottom: 0em;
}

.nav .dropdown img {
  max-height: 2em;
}

.section-dividers {
  width:50px;
  height:50px;
  position: absolute;
  margin:-25px 0 0 -25px;
}

.section-divider {
  text-align: center;
  transform: translateY(50%) rotate(45deg);
}