
/* .redeem-hero {
} */

.redeem-hero h1 {
    text-transform: uppercase;
    font-size: 3.8em;
}
.redeem-hero h1, .redeem-hero p.description {
    text-align: center;
}
.redeem-hero p.description {
    font-size: 1.3em;
    font-weight: 300;
}

.redeem-add-token {
    background-color: white;
}
.redeem-add-token h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: lightgrey;
    text-align: center;
    margin-bottom: 1em;
}
.redeem-add-token h3 {
    color: black;
}
.redeem-add-token p,
.redeem-add-token ol,
.redeem-add-token li {
    color: black;
    font-size: 1.0em;
}
.redeem-add-token p {
    font-size: 1.1em;
}
.redeem-add-token p.description {
    text-align: center;
    color: rgb(55, 130, 153);
}

.redeem-partners .btn {
    margin-right: 1em !important;
    margin-bottom: 1em !important;
}

.modal-dialog .modal-header button.close {
    background-color: white;
    border: 0px;
}

.section-divider {
    display: none;
}