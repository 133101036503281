.not-found-hero h1 {
    text-transform: uppercase;
    font-size: 3.8em;
}
.not-found-hero h1, .not-found-hero p.description {
    text-align: center;
}
.not-found-hero p.description {
    font-size: 1.3em;
    font-weight: 300;
}