.crowdsale-intro {
    min-height: 60vh;
    overflow: auto;
    padding-bottom: 10vh;
}
.crowdsale-step-one {
    min-height: 70vh;
    overflow: auto;
    padding-bottom: 10vh;
}

/* .crowdsale-hero {
} */

.crowdsale-hero h1 {
    text-transform: uppercase;
    font-size: 3.0em;
}
.crowdsale-hero h1, .crowdsale-hero p.description {
    text-align: center;
}
.crowdsale-hero p.description {
    font-size: 1.3em;
    font-weight: 300;
}

.accent, .crowdsale-intro a {
    color: #ffb400;
}

.crowdsale-intro .btn,
.crowdsale-step-one .btn {
    text-transform: uppercase;
    transition: all 0.2s;
    color: white;
}

.crowdsale-intro .btn.btn-lg {
    font-size: 1.5em;
}
.crowdsale-intro .progress {
    height: 20px;
}
.crowdsale-step-one {
    background-color: white;
}
.crowdsale-step-one h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: lightgrey;
    text-align: center;
    margin-bottom: 1em;
}

.crowdsale-step-one p {
    color: black;
    font-size: 1.2em;
}
.crowdsale-step-one p.description {
    text-align: center;
    color: rgb(55, 130, 153);
}


.crowdsale-add-token {
/*    background-color: white; */
}
.crowdsale-add-token h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: white;
    text-align: center;
    margin-bottom: 1em;
}
.crowdsale-add-token h3 {
    color: white;
}
.crowdsale-add-token p,
.crowdsale-add-token ol,
.crowdsale-add-token li {
    color: white;
    font-size: 1.0em;
}
.crowdsale-add-token table {
    background-color: rgba(255,255,255,0.9);
}
.crowdsale-add-token a {
    color: #ffb400;
}
.crowdsale-add-token a {
    font-weight: bold;
}
.crowdsale-add-token p {
    font-size: 1.1em;
}
.crowdsale-add-token p.description {
    text-align: center;
    color: rgb(55, 130, 153);
}


.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.strong {
    font-weight: bold;
}

.info-box {
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
}



.fancy {
    line-height: 0.5;
    text-align: center;
  }
.fancy span {
    display: inline-block;
    position: relative;  
}
.fancy span:before,
.fancy span:after {
    content: "";
    position: absolute;
    height: 50%;
    border-bottom: 1px solid lightgrey;
    /* border-top: 1px solid lightgrey; */
    top: 0;
    width: 100%;
}
.fancy span:before {
    right: 120%;
    margin-right: 15px;
}
.fancy span:after {
    left: 120%;
    margin-left: 15px;
}

.section-divider {
    display: none;
}